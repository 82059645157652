import { graphql } from 'gatsby';
import * as React from 'react';
import SectionLeftRightComponent from '../../components/section-left-right/section-left-right.component';
import { buildSections } from '../../services/sections';
import TagComponent from '../../components/tag/tag.component';
import AnchorButtonComponent from '../../components/anchor-button/anchor-button.component';
import BreadcrumbComponent from '../../components/breadcrumb/breadcrumb.component';
import {Seo} from "../../components/seo/seo";

const NoResults = ({data}) => {
    const breadcrumbPath = [{title:'Localiser une agence', url:'/'}, {title:'Pas d’agence Paritel'}];
    const sections = data.cms.pages.data[0].attributes.sections;
    const pageData = data.cms.pages.data[0].attributes;

    return (
        <div className="container-fluid">
            <Seo pageContext={{}} pageData={pageData} />
            <SectionLeftRightComponent image="https://res.cloudinary.com/yzy-paritel/image/upload/v1640000553/store_locator/no-results-image_byvvqf_qhrmdf.png">
                <BreadcrumbComponent path={breadcrumbPath} />
                <div className="block">
                    <TagComponent title="RECHERCHER UNE AGENCE" />
                    <h3>Pas d’agence Paritel pour votre recherche</h3>
                    <p>
                        Vous pouvez faire une nouvelle recherche en utilisant la barre 
                        de recherche située au dessus ou retourner à la page d’accueil 
                        en cliquant sur le bouton ci-dessous
                    </p>
                    <AnchorButtonComponent kind="flat" text="Rechercher une agence" url="/" />
                </div>
            </SectionLeftRightComponent>
            {
                buildSections(sections, null, null, null, [], 0)
            }
        </div>
    );
}

export default NoResults;

export const query = graphql`
query noResultsQuery {
    cms {
        pages(filters: {name: {eq: "storelocator/no_result"}}) {
            data {
                attributes {
                    sections {
                        __typename
                        ... on CMS_ComponentStoreLocatorSectionTexteImage {
                            id
                            bouton {
                                titre
                                style
                                url
                                action
                            }
                            slide {
                                id
                                tag
                                texte
                                titre
                            }
                            image {
                                data {
                                    attributes {
                                        alternativeText
                                        url
                                    }
                                }
                            }
                        }
                        ... on CMS_ComponentStoreLocatorSectionCarteAgences {
                            id
                        }              
                    }
                    
                    seoMetaDescription
                    seoMetaTitle
                }
            }
        }
    }
}
`